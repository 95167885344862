import React, { useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import parse from "html-react-parser";
import { customerView, CountryRegions, orderHistory, orderPlaced, refund, transactionRedeem, voucherPurchase } from "../../services/services";
import { getDate, alertModal } from "../../utils/helper";
import Loader from "../partial/Loader";
import { giftCard, voucher, backArrow } from "../../Image/Index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { BarCode, sendEmailData } from "../../services/auth";
import swal from "sweetalert";
import { decrypt } from "../../utils/encodingdecoding";

const RedeemCard1 = ({ detail, type, setShowDetail, data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        walletsDetail: {},
    });

    const imageData = localStorage.getItem("blob");
    const name = localStorage.getItem("name");
    const surname = localStorage.getItem("surname");
    const firstNameData = localStorage.getItem("firstName");
    const [loader, setLoader] = useState(false);
    const [redeemState, setRedeemState] = useState({
        currencySymbol: "",
        country: "",
        redeemPoints: null,
        amount: "",
    });
    const { currencySymbol, country, redeemPoints, amount } = redeemState;

    useEffect(() => {
        const countryRegion = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const response = await CountryRegions(token.access_token);
            let country = {};
            // eslint-disable-next-line
            response?.filter((item) => {
                if (item?.name === detail?.countries[0]?.code) {
                    country = {
                        symbol: getSymbolFromCurrency(item.isoCurrencySymbol),
                        name: item.name,
                    };
                    return country;
                }
            });
            setRedeemState((prev) => ({
                ...prev,
                currencySymbol: country.symbol,
                country: country.name,
            }));
            // setLoader(false);
        };
        if (type === "giftCard") {
            countryRegion();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (type === "giftCard") {
            let temp = 0;
            detail?.vendors?.map((ele) => {
                temp = ele?.denominations[0]?.fix_value;
                return temp;
            });
            setRedeemState((prev) => ({
                ...prev,
                redeemPoints: Number(temp),
            }));
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setRedeemState((prev) => ({
                ...prev,
                [e.target.name]: inputValue,
            }));
        }
    };

    const handleAmount = (value) => {
        setRedeemState((prev) => ({
            ...prev,
            amount: value * 10,
        }));
    };
    const customerCode = localStorage.getItem("c_code");

    useEffect(() => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        customerView(token.access_token, customerCode).then((res) => {
            setState((prev) => ({
                ...prev,
                walletsDetail: res?.walletsDetail,
            }));
            setLoader(false);
        });
    }, []);

    const { walletsDetail } = state;

    const handleGiftCard = (detail) => {
        alertModal("center", "warning", "Are you sure?", "buttons", ["Cancel", "Confirm"], 5000).then((value) => {
            if (value === true) {
                redeemGiftCard(detail);
            }
        });

        const redeemGiftCard = async (detail) => {
            setLoader(true);
            const redeemBody = {
                customer: { customercode: customerCode },
                redeemDetail: { walletType: "point", values: Math.ceil(redeemPoints * 4), transactionType: "redeem", transactionDate: getDate(new Date(), "YYYY-MM-DD") },
            };
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const redeemRes = await transactionRedeem(token.access_token, JSON.stringify(redeemBody));
           
            setLoader(false);
        };
    };

    const handleVoucher = async (detail, value, fixPoint) => {
        alertModal("center", "warning", "Are you sure?", "buttons", ["Cancel", "Confirm"], 5000).then((value) => {
            if (value === true) {
                purchaseVoucher(detail);
            }
        });
        const purchaseVoucher = async () => {
            const customerCode = localStorage.getItem("c_code");
            setLoader(true);
            const redeemBody = {
                customer: { customercode: customerCode },
                redeemDetail: { walletType: "point", values: parseInt(value) * 4, transactionType: "redeem", transactionDate: getDate(new Date(), "YYYY-MM-DD") },
            };
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const redeemRes = await transactionRedeem(token.access_token, JSON.stringify(redeemBody));

            if (redeemRes?.message == "Request processed successfully.") {
                const voucherBody = {
                    voucherTemplateCode: detail?.code,
                    merchantId: detail?.merchantId[0],
                    customerCode: customerCode,
                    value: parseInt(value * 4),
                    amount: parseInt(value),
                    RedeemTransactionId: redeemRes.data.currentTransactionId,
                };
                const voucherRes = await voucherPurchase(token.access_token, voucherBody);
                if (voucherRes?.message === "Request processed successfully.") {
                    const token = JSON.parse(localStorage.getItem("token_gen"));
                    const response = await BarCode(token?.access_token, voucherRes?.data?.code);
                    const barcodeImage = response?.url;

                    const tempEmail = {
                        subject: `Congratulations, ${localStorage.getItem("name")}! You've Claimed Your Reward Points`,
                        sendgridApiKey: "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",
                        fromEmail: "support@loyalty.novusloyalty.com",
                        fromName: `${data?.companyname}`,
                        emails: [
                            {
                                email: localStorage.getItem("email"),

                                // name: localStorage.getItem("name"),
                            },
                        ],
                        body: `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Congratulations, Your Voucher is Ready</title>
</head>


<body>
    <style>
        ol {
            padding: 0 0 0 25px;
        }

        ol li {
            padding: 5px 0;
            font-size: 14px;
        }

        ul {
            padding: 0 0 0 15px;
        }

        ul li {
            padding: 5px 0;
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }
    </style>
    <table class="register" align="center" border="0" cellpadding="0" cellspacing="0"
        style="background-color:#fff; border-radius:20px; font-family:arial,sans-serif; padding:15px 10px 30px 10px; width:100%">
        <tbody>
            <tr>
                <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="mainBody"
                        style="width:600px">
                        <tbody>
                            <tr>
                                <td>
                                    <table border="0" cellpadding="0" cellspacing="0"
                                        style="background:#ffffff; border-radius:20px; line-height:1.5; padding:0 0 15px 0; width:100%">
                                        <tbody>
                                            <tr>
                                                <td style="width:100%">
                                                    <img src=${imageData} alt="logo"
                                                        style="width: 100%; border-radius:20px 20px 0 0;">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align:center">
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="padding:20px 25px 0 25px; width:100%">
                                                        <tbody>
                                                            <tr>
                                                                <td style="text-align:left">
                                                                    <br>
                                                                    <h4>Hi ${localStorage.getItem("name")},</h4>
                                                                    <p style="font-family:Arial,Helvetica,sans-serif">
                                                                        Great news!</p>

                                                                    <p>You’ve just claimed your ${data.companyname} Rewards Points. See voucher details below:</p>
<table width="580" border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff" style="margin: 0 auto; width: 580px; background: #fff;ont-family:Arial,Helvetica,sans-serif;font-size:14px;">
<tr><td style = "vertical-align:top;text-align:left";>Value: </td><td style = "vertical-align:top;text-align:left";> TT$ ${voucherRes?.data?.value} </td></tr>
<tr><td style = "vertical-align:top;text-align:left";>Voucher Code: </td> <td style = "vertical-align:top;text-align:left";> ${voucherRes?.data?.code} <br> <img width='130'; height="130" src=${barcodeImage} alt="Voucher Barcode"/></td></tr>

<tr><td style = "vertical-align:top;text-align:left";>Expiry Date: </td><td style = "vertical-align:top;text-align:left";> ${getDate(voucherRes?.data?.expiryDate, "DD-MM-YYYY")}</td></tr>
</table>
                                                                


                                                                    <p style="font-weight: 700;">Terms & Conditions: </p>
                                                                    <p>Gift cards are non-exchangeable, non-refundable, and non-cancellable once purchased. </p>

                                                                    <p>Thank you for your continued support!</p>

                                                                    <p>Best Regards, <br>
                                                                        Your ${data.companyname} Family 
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>`,
                        purpose: "",
                        fileName: "",
                    };

                    sendEmailData(token.access_token, tempEmail);
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Voucher purchase successfully",
                        // timer: 2000,
                        button: "OK",
                    }).then(function () {
                        navigate("/customer-portal");
                    });
                    // alertModal("center", "success", "Voucher purchase successfully", "button", "OK");
                    setLoader(false);
                } else {
                    let date = redeemRes?.data?.Wallets?.map((ele) => {
                        return ele?.transactionData?.find((item) => {
                            return item.transactionId === redeemRes.data.currentTransactionId;
                        });
                    });
                    const refundBody = {
                        customer: {
                            customercode: customerCode,
                        },
                        transactionId: redeemRes?.data?.currentTransactionId,
                        transactionAmount: 0,
                        transactionDate: getDate(date[0]?.transactionDate, "YYYY-MM-DD"),
                    };
                    const refundRes = await refund(token.access_token, refundBody);
                    const customerRes = await customerView(token.access_token, customerCode);
                    // const customerRes = [];

                    setState((prev) => ({
                        ...prev,
                        walletsDetail: customerRes?.walletsDetail,
                        activityLogs: customerRes?.activityLogs,
                    }));
                    if (refundRes?.message === "Request processed successfully.") {
                        alertModal("center", "error", "Voucher purchase failed", "button", "OK", 5000);
                    } else {
                        alertModal("center", "error", refundRes?.message, "button", "OK", 5000);
                    }
                    setLoader(false);
                }
            } else {
                alertModal("center", "error", redeemRes?.message, "button", "OK", 5000);
                setLoader(false);
            }
        };
    };

    const onSubmit = () => {};

    return (
        <>
            {loader && <Loader />}
            <div className="allCardContainer">
                <div className="function_row">
                    <div className="">
                        <Link
                            className="backAro"
                            state={{ type: location?.state?.selectedValue }}
                            onClick={() => {
                                setShowDetail((prev) => !prev);
                            }}
                        >
                            <img src={backArrow} alt="Back" height={16} />
                            Back
                        </Link>
                    </div>
                    {/* <div className="points_box">
                        <div className="header_points_box">
                            <h4>Your Points <img src={'/Images/star-silver.png'} /> <span>{walletsDetail?.availableValue}</span></h4>
                        </div>
                    </div> */}
                </div>
                {type === "giftCard" ? (
                    <div className=" d-flex justify-content-between giftCardDetail">
                        <div className="giftCardDetail__left p-5rem border rounded">
                            <img src={detail?.image ? detail?.image : giftCard} alt="" style={{ maxWidth: "600px", width: "100%" }} />
                        </div>
                        <div className="giftCardDetail__right">
                            <span className="card_denominations">
                                {detail?.vendors?.length > 0 &&
                                    detail?.vendors.map((ele) => {
                                        return ele.denominations.map((ele, i) => {
                                            return (
                                                <span key={i} className="card_denominations__btn">
                                                    <button
                                                        className="btn"
                                                        style={{ background: redeemPoints === Number(ele.fix_value) ? "#4545c7" : "#000000", color: "#ffffff" }}
                                                        onClick={() =>
                                                            setRedeemState((prev) => ({
                                                                ...prev,
                                                                redeemPoints: Number(ele.fix_value),
                                                            }))
                                                        }
                                                    >
                                                        <span className="" title={ele.fix_value}>
                                                            <span>{currencySymbol}</span>
                                                            {ele.fix_value}
                                                        </span>
                                                    </button>
                                                </span>
                                            );
                                        });
                                    })}
                            </span>
                            <h5>{detail?.title}</h5>
                            <hr></hr>
                            <span>{parse(detail?.description)}</span>
                            <hr></hr>
                            {/* <div className="row"> */}
                            <button className="btn btn-primary col-sm-12 col-md-12 col-lg-12 btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < redeemPoints * 4} onClick={() => handleGiftCard(detail)}>
                                REDEEM FOR {Math.ceil(4 * redeemPoints)} POINTS
                            </button>
                            {/* </div> */}
                        </div>
                    </div>
                ) : (
                    <div className="giftCardDetail">
                        <div className="giftCardDetail__left border  rounded p-5rem">
                            <img src={detail?.imageUpload ? detail?.imageUpload : voucher} alt="fig" />
                        </div>
                        <div className="giftCardDetail__right">
                            <h4 className="lh-sm">
                                <span>{detail?.name}</span>
                            </h4>
                            <div className="gc_amount">{detail?.description}</div>
                            <hr></hr>
                            <ValidatorForm className="signUpPage__formSection" onSubmit={(e) => onSubmit(e)}>
                                {detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 1 && detail.voucherValue == 0 && (
                                    <>
                                        <span className="card_denominations">
                                            {Array.from([1, 2, 3, 4, 5], (x, i) => (
                                                <span className="card_denominations__btn" key={i}>
                                                    <button type="button" onClick={() => handleAmount(x)} disabled={x * 10 > walletsDetail?.TotalavailableValue}>
                                                        {x * 10}
                                                    </button>
                                                </span>
                                            ))}
                                        </span>
                                        <div className="my-3">
                                            <TextValidator className="form-control" name="amount" maxLength="3" value={amount} onChange={handleChange} validators={["required"]} errorMessages={["Amount is required."]} />
                                        </div>
                                    </>
                                )}
                                {detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 0 && parseInt(detail.voucherValue) > 0 ? (
                                    <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < detail?.voucherValue || walletsDetail?.TotalavailableValue <= 0 || detail?.voucherValue <= 0} onClick={() => handleVoucher(detail, parseInt(detail?.voucherValue), "fix-point")}>
                                        REDEEM FOR {detail?.voucherValue} POINTS
                                    </button>
                                ) : detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 1 && (detail.voucherValue === 0 || "0" || null) ? (
                                    <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < amount * 4 || walletsDetail?.TotalavailableValue <= 0 || amount * 4 <= 0} onClick={() => handleVoucher(detail, parseInt(amount))}>
                                        REDEEM FOR {amount * 4} POINTS
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < detail?.voucherValue || walletsDetail?.TotalavailableValue <= 0 || detail?.voucherValue <= 0} onClick={() => handleVoucher(detail, parseInt(detail?.voucherValue))}>
                                        REDEEM FOR {detail?.voucherValue} POINTS
                                    </button>
                                )}
                            </ValidatorForm>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RedeemCard1;
